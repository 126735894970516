<template>
  <div class="p-1">
    <validation-observer
      ref="observer"
      v-slot="{ pristine, invalid, handleSubmit }"
    >
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <!-- Locale -->
          <b-col sm="12">
            <validation-provider
              v-slot="validationContext"
              :ref="`locale_id`"
              :rules="{ required: true }"
              vid="locale_id"
              name="language"
            >
              <b-form-group
                label="Language"
                label-for="landing-page-locale"
              >
                <v-select
                  id="landing-page-locale"
                  v-model="landingPage.localeId"
                  label="text"
                  :options="localeOptions"
                  placeholder="Select the language"
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                />
                  
                <b-form-invalid-feedback
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <!-- Header -->
          <b-col sm="6">
            <validation-provider
              v-slot="{ errors }"
              :ref="`header`"
              :rules="{ required: true }"
              name="header"
            >
              <b-form-group
                label="Header"
                label-for="landing-page-header"
              >
                <b-form-input
                  v-model="landingPage.header"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Header"
                  name="landing-page-header"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Tagline -->
          <b-col sm="6">
            <validation-provider
              v-slot="{ errors }"
              :ref="`Tagline`"
              :rules="{}"
              name="Tagline"
              :vid="`Tagline`"
            >
              <b-form-group
                label="Tagline"
                label-for="landing-page-Tagline"
              >
                <b-form-input
                  v-model="landingPage.tagline"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Tagline"
                  name="landing-page-Tagline"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Description -->
        <b-row class="mt-2">
          <b-col sm="12">
            <validation-provider
              v-slot="validationContext"
              :ref="`programBody`"
              rules="required"
              vid="programBody"
              name="Description"
            >
              <h5>Description</h5>
              <aom-text-editor
                v-if="showAOMTextEditor"
                v-model="landingPage.description"
                :state="getValidationState(validationContext)"
                :height="100"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Social -->
        <div class="mt-2">
          <h5>Social</h5>
          <b-row>
            <b-col sm="6">
              <validation-provider
                v-slot="{ errors }"
                :ref="`facebook`"
                :rules="{ url_with_protocol: true }"
                name="Facebook"
              >
                <b-form-group
                  label="Facebook"
                  label-for="social-facebook"
                >
                  <b-form-input
                    v-model="landingPage.social.facebook"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Facebook"
                    name="social-facebook"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col sm="6">
              <validation-provider
                v-slot="{ errors }"
                :ref="`twitter`"
                :rules="{ url_with_protocol: true }"
                name="Twitter"
              >
                <b-form-group
                  label="Twitter"
                  label-for="social-twitter"
                >
                  <b-form-input
                    v-model="landingPage.social.twitter"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Twitter"
                    name="social-twitter"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="6">
              <validation-provider
                v-slot="{ errors }"
                :ref="`youTube`"
                :rules="{ url_with_protocol: true }"
                name="YouTube"
              >
                <b-form-group
                  label="YouTube"
                  label-for="social-youTube"
                >
                  <b-form-input
                    v-model="landingPage.social.youTube"
                    :state="errors.length > 0 ? false : null"
                    placeholder="YouTube"
                    name="social-youTube"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col sm="6">
              <validation-provider
                v-slot="{ errors }"
                :ref="`linkedin`"
                :rules="{ url_with_protocol: true }"
                name="Linkedin"
              >
                <b-form-group
                  label="Linkedin"
                  label-for="social-linkedin"
                >
                  <b-form-input
                    v-model="landingPage.social.linkedin"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Linkedin"
                    name="social-linkedin"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="6">
              <validation-provider
                v-slot="{ errors }"
                :ref="`email`"
                :rules="{ email: true }"
                name="Email"
              >
                <b-form-group
                  label="Email"
                  label-for="social-email"
                >
                  <b-form-input
                    v-model="landingPage.social.email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Email"
                    name="social-email"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col sm="6">
              <validation-provider
                v-slot="{ errors }"
                :ref="`website`"
                :rules="{ url_with_protocol: true }"
                name="Website"
              >
                <b-form-group
                  label="Website"
                  label-for="social-website"
                >
                  <b-form-input
                    v-model="landingPage.social.website"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Website"
                    name="social-website"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="6">
              <validation-provider
                v-slot="{ errors }"
                :ref="`recruitment-video`"
                :rules="{ url_with_protocol: true }"
                name="Recruitment Video"
              >
                <b-form-group
                  label="Recruitment Video"
                  label-for="social-recruitment-video"
                >
                  <b-form-input
                    v-model="landingPage.social.recruitmentVideo"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Recruitment Video"
                    name="social-recruitment-video"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </div>

        <!-- Mentee description -->
        <div class="mt-3">
          <b-row class="mt-2">
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                :ref="`menteeResponsibilitiesBody`"
                rules=""
                vid="menteeResponsibilitiesBody"
                :name="roleAlias(userRoles.MENTEE, defaultProgram) + &quot;Responsibilities Body&quot;"
              >
                <h5>{{ roleAlias(userRoles.MENTEE, defaultProgram) }} Responsibilities Body</h5>
                <aom-text-editor
                  v-if="showAOMTextEditor"
                  v-model="landingPage.menteeResponsibilitiesBody"
                  :state="getValidationState(validationContext)"
                  :height="100"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>
        </div>

        <!-- Mentor description -->
        <div class="mt-3">
          <b-row class="mt-2">
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                :ref="`mentorResponsibilitiesBody`"
                rules=""
                vid="mentorResponsibilitiesBody"
                :name="roleAlias(userRoles.MENTOR, defaultProgram) + &quot;Responsibilities Body&quot;"
              >
                <h5>{{ roleAlias(userRoles.MENTOR, defaultProgram) }} Responsibilities Body</h5>
                <aom-text-editor
                  v-if="showAOMTextEditor"
                  v-model="landingPage.mentorResponsibilitiesBody"
                  :state="getValidationState(validationContext)"
                  :height="100"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>
        </div>

        <!-- Image -->
        <b-row class="mt-3">
          <b-col sm="12">
            <validation-provider
              v-slot="validationContext"
              ref="image"
              :rules="{ required: true }"
              vid="image"
              name="image"
            >
              <b-form-group
                label="Image (Allowed .jpg, .jpeg, .gif, .png, .bmp, .wbmp). Max size of 5MB"
                label-for="image"
              >
                <b-form-file
                  id="image"
                  ref="refInputEl"
                  v-model="landingPage.blogFile"
                  name="image"
                  :state="getValidationState(validationContext)"
                  placeholder="Image"
                  accept="image/*"
                  drop-placeholder="Drop file here..."
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Cancel and submit -->
        <b-row class="mt-5">
          <b-col
            sm="6"
            md="4"
            class="mb-1"
          >
            <b-button
              block
              type="reset"
              variant="outline-dark"
              @click="onReset"
            >
              Cancel
            </b-button>
          </b-col>
          <b-col
            sm="6"
            md="4"
            class="mb-1"
          >
            <b-button
              block
              type="submit"
              variant="primary"
            >
              <b-spinner
                v-if="isUpdating"
                small
              />
              <span v-if="isUpdating"> Updating...</span>
              <span v-else>Submit</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BButton,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BSpinner,
  BFormInput,
  BFormFile,
  BFormInvalidFeedback
} from "bootstrap-vue";
import landingPagesService from "@/services/landingPagesService";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AomTextEditor from "@aom-core/AoMTextEditor.vue";
import { linkTypes, userRoles } from "@/models";
import { getValidationState } from "@/libs/utils";
import { makeSuccessToast, makeErrorToast, convertModelToFormData } from "@/libs/utils";
import vSelect from "vue-select";
import { roleAlias } from "@aom-core/utils/utils";

const defaultLandingPage = {
  id: null,
  header: "",
  tagline: "",
  description: "",
  localeId: null,
  blogFile: null,
  social: {
    facebook: "",
    twitter: "",
    youTube: "",
    linkedin: "",
    email: "",
    website: "",
    recruitmentVideo: "",
  },
  image: [],
  menteeResponsibilitiesBody: null,
  mentorResponsibilitiesBody: null,
};

export default {
  components: {
    BButton,
    BForm,
    BSpinner,
    BFormGroup,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    AomTextEditor,
    BFormInput,
    BFormFile,
    BFormInvalidFeedback,
    vSelect
  },

  props: {
    showAOMTextEditor: {
      type: Boolean
    },
    unavailableLocales: {
      type: Array
    }
  },

  data() {
    return {
      defaultLandingPage,
      landingPage: defaultLandingPage,
      isLoading: false,
      isUpdating: false,
    };
  },

  computed: {
    ...mapGetters("programs", ['programDefaultLangs', 'defaultProgram']),

    localeOptions() {
      return this.programDefaultLangs.map(locale => ({
        value: locale.id, text: locale.name
      })).filter(locale => !this.unavailableLocales.includes(locale.value));
    }
  },

  methods: {
    getValidationState(context) {
      return getValidationState(context);
    },

    async onSubmit() {
      if (this.landingPage.blogFile) {
        const fileSize = this.landingPage.blogFile.size;
        const fileSizeInMB = fileSize / (1024 * 1024);
        if (fileSizeInMB > 5) {
          this.$refs.observer.setErrors(
            { image: ['Image must be less than 5MB'] }
          );
          return false;
        }
      }

      const payload = {
        locale_id: Number(this.landingPage.localeId?.value),
        header: this.landingPage.header,
        description: this.landingPage.description,
        tagline: this.landingPage.tagline,
        image: {
          file: this.landingPage.blogFile,
        }
      };
      // Prepare role description
      const roleDescriptions = [
        { role_id: userRoles.MENTOR, description: this.landingPage.mentorResponsibilitiesBody },
        { role_id: userRoles.MENTEE, description: this.landingPage.menteeResponsibilitiesBody },
      ];
      payload.landing_page_role_descriptions = roleDescriptions.filter(d => !!d.description);
      // Prepare links
      const links = [];
      if (this.landingPage.social.facebook) {
        links.push({ type_id: linkTypes.FACEBOOK, url: this.landingPage.social.facebook });
      }
      if (this.landingPage.social.twitter) {
        links.push({ type_id: linkTypes.TWITTER, url: this.landingPage.social.twitter });
      }
      if (this.landingPage.social.youTube) {
        links.push({ type_id: linkTypes.YOUTUBE, url: this.landingPage.social.youTube });
      }
      if (this.landingPage.social.linkedin) {
        links.push({ type_id: linkTypes.LINKEDIN, url: this.landingPage.social.linkedin });
      }
      if (this.landingPage.social.email) {
        links.push({ type_id: linkTypes.EMAIL, url: this.landingPage.social.email });
      }
      if (this.landingPage.social.website) {
        links.push({ type_id: linkTypes.WEBSITE, url: this.landingPage.social.website });
      }
      if (this.landingPage.social.recruitmentVideo) {
        links.push({ type_id: linkTypes.RECRUITMENT_VIDEO, url: this.landingPage.social.recruitmentVideo });
      }
      payload.landing_page_links = links;

      // Submit api
      try {
        this.isUpdating = true;
        const response = await landingPagesService.add(
          this.$route.params.id,
          convertModelToFormData(payload)
        );

        if (response) {
          this.$emit("landingPageAdded", response.data.id);
          this.$toast(makeSuccessToast("Landing page created successfully."));
          this.landingPage = this.defaultLandingPage;
          this.$root.$emit("bv::toggle::collapse", "add-landing-page-right");
        }
      } catch (e) {
        this.$toast(
          makeErrorToast("Something went wrong. Landing page not added.")
        );
        this.$log.error(e);
      } finally {
        this.isUpdating = false;
      }
    },

    onReset() {
      this.landingPage = this.defaultLandingPage;
      this.$root.$emit("bv::toggle::collapse", "add-landing-page-right");
    },
  },
  setup() {
    return {
      roleAlias,
      userRoles
    };
  }
};
</script>
